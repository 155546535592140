import React from 'react';
import map from 'lodash/fp/map';
import { useIntl } from 'react-intl';

import { LoanSubstepProps } from 'GlobalTypes';

import { Field, TextField as BasicTextField } from '@kwara/components/src/Form';
import { PeriodUnits } from '@kwara/models/src';
import { ChargeFrequencies } from '@kwara/models/src/models/LoanProduct';
import { add } from '@kwara/lib/src/currency';

import { validateLoanAmount } from '../../utils/validateLoanAmount';
import { LoanRepaymentFields } from '../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields';
import { DurationField } from '../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields/DurationField';
import { useLoanRepaymentFields } from '../../../Loan/LoanAdd/components/LoanConfigure/LoanRepaymentFields/useLoanRepaymentFields';

const sharedCurrencyProps = {
  isCurrency: true,
  type: 'number',
  leftGlyph: 'Currency.orgCurrency'
};

function TopupAmountAndAccountTerms(props: LoanSubstepProps) {
  const intl = useIntl();

  const {
    data: {
      loan: { product, amount, loanDuration, feeApplications, repaymentPeriodUnit },
      totalRolloverBalance
    },
    formProps: {
      values: { topupAmount }
    },
    SelectField,
    TextField
  } = props;

  const { fixedRepayment } = useLoanRepaymentFields({
    product,
    amount,
    feeApplications,
    loanDuration,
    repaymentPeriodUnit
  });

  const newPrincipalBalance = add(totalRolloverBalance, topupAmount || 0);

  return (
    <>
      <TextField
        {...sharedCurrencyProps}
        required={true}
        name="topupAmount"
        labelId="LoanTopupV1.TopupAmountAndAccountTerms.topupAmount"
      />
      <TextField
        {...sharedCurrencyProps}
        name="totalRolloverBalance"
        labelId="LoanTopupV1.TopupAmountAndAccountTerms.totalRolloverBalance"
        readOnly
      />

      <Field
        name="newPrincipalBalance"
        size="medium"
        labelId="LoanTopupV1.TopupAmountAndAccountTerms.newPrincipalBalance"
      >
        <BasicTextField
          name="newPrincipalBalance"
          value={newPrincipalBalance}
          size="medium"
          type="number"
          leftGlyph="Currency.orgCurrency"
          readOnly
        />
      </Field>

      <div className="mt3">
        <TextField
          name="annualInterestRate"
          labelId="LoanTopupV1.TopupAmountAndAccountTerms.interestRate"
          required={true}
          leftGlyph={intl.formatMessage({ id: 'General.%' })}
          values={{
            frequency: intl.formatMessage({
              id: `LoanProductAdd.Product.interestChargeFrequency.${ChargeFrequencies.ANNUALIZED}`
            })
          }}
          readOnly
        />

        <Field
          margin={false}
          name="repaymentPeriod"
          size="medium"
          required={true}
          disabled={fixedRepayment}
          labelId="LoanAdd.Configure.Repayment.label"
        >
          <div className="flex">
            <TextField disabled={fixedRepayment} size="medium" name="repaymentPeriod" />
            <SelectField disabled={fixedRepayment} name="repaymentPeriodUnit" size="medium">
              {map(
                unit => (
                  <SelectField.Option key={unit} translationId={`LoanAdd.Configure.Repayment.${unit}`} value={unit} />
                ),
                PeriodUnits
              )}
            </SelectField>
          </div>
        </Field>

        <DurationField {...props} />
      </div>
    </>
  );
}

export default {
  Component: TopupAmountAndAccountTerms,
  validate: data => ({
    topupAmount: {
      isRequired: () => true,
      currency: true,
      nonZero: true,
      custom: validateLoanAmount
    },
    repaymentPeriod: LoanRepaymentFields.validate(data).repaymentPeriod,
    loanDuration: LoanRepaymentFields.validate(data).loanDuration
  })
};
