import { SequentialStepStepConfig } from '@kwara/components/src/Wizard/latest/types/sharedTypes';

import Balances from './components/Balance';
import Charges from './components/Charges';
import Purpose from './components/Purpose';
import Schedule from './components/Schedule';
import Summary from './components/Summary';
import Security from './components/Security';
import AccountMemberAndDetails from './components/AccountMemberAndDetails';
import DisbursementAndRepayment from './components/DisbursementAndRepayment';
import TopupAmountAndAccountTerms from './components/TopupAmountAndAccountTerms';

export const steps: SequentialStepStepConfig = {
  accountAndMemberDetails: {
    titleId: 'LoanTopupV1.AccountAndMemberDetails.LeftNavigation.title',
    subtitleId: 'LoanTopupV1.AccountAndMemberDetails.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'disbursementAndRepayment' }],
    children: [
      {
        titleId: 'LoanTopup.AccountAndMemberDetails.title',
        Component: AccountMemberAndDetails.Component
      }
    ]
  },
  disbursementAndRepayment: {
    titleId: 'LoanTopupV1.DisbursementAndRepayment.LeftNavigation.title',
    subtitleId: 'LoanTopupV1.DisbursementAndRepayment.LeftNavigation.subtitle',
    actions: [
      {
        appearsAs: 'next',
        behavesAs: 'nextWithPromise',
        destination: 'balances',
        onNext: DisbursementAndRepayment.fetchBalances
      }
    ],
    children: [
      {
        titleId: 'LoanTopupV1.DisbursementAndRepayment.title',
        Component: DisbursementAndRepayment.Component,
        validate: DisbursementAndRepayment.validate
      }
    ]
  },
  balances: {
    titleId: 'LoanTopupV1.Balances.LeftNavigation.title',
    subtitleId: 'LoanTopupV1.Balances.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'topupAmountAndAccountTerms' }],
    children: [
      {
        titleId: 'LoanTopupV1.Balances.title',
        Component: Balances.Component,
        validate: Balances.validate
      }
    ]
  },
  topupAmountAndAccountTerms: {
    titleId: 'LoanTopup.TopupAmountAndAccountTerms.LeftNavigation.title',
    subtitleId: 'LoanTopup.TopupAmountAndAccountTerms.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'charges' }],
    children: [
      {
        titleId: 'LoanTopup.TopupAmountAndAccountTerms.title',
        Component: TopupAmountAndAccountTerms.Component,
        validate: TopupAmountAndAccountTerms.validate
      }
    ]
  },

  charges: {
    titleId: 'LoanTopup.Charges.LeftNavigation.title',
    subtitleId: 'LoanTopup.Charges.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'schedulePreview' }],
    children: [
      {
        titleId: 'LoanTopup.Charges.title',
        Component: Charges.Component,
        validate: Charges.validate
      }
    ]
  },

  schedulePreview: {
    titleId: 'LoanTopup.SchedulePreview.LeftNavigation.title',
    subtitleId: 'LoanTopup.SchedulePreview.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'security' }],
    children: [
      {
        titleId: 'LoanTopup.SchedulePreview.title',
        Component: Schedule.Component
      }
    ]
  },

  security: {
    titleId: 'LoanTopup.Security.LeftNavigation.title',
    subtitleId: 'LoanTopup.Security.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'loanPurpose' }],
    children: [
      {
        titleId: 'LoanTopup.Security.title',
        Component: Security.Component,
        validate: Security.validate
      }
    ]
  },
  loanPurpose: {
    titleId: 'LoanTopup.Purpose.LeftNavigation.title',
    subtitleId: 'LoanTopup.Purpose.LeftNavigation.subtitle',
    actions: [{ appearsAs: 'next', behavesAs: 'next', destination: 'summary' }],
    children: [
      {
        titleId: 'LoanTopup.Purpose.title',
        Component: Purpose.Component,
        validate: Purpose.validate
      }
    ]
  },
  summary: {
    titleId: 'LoanTopup.Summary.LeftNavigation.title',
    subtitleId: 'LoanTopup.Summary.LeftNavigation.subtitle',
    actions: [
      {
        appearsAs: 'submit',
        behavesAs: 'approve'
      }
    ],
    children: [
      {
        titleId: 'LoanTopup.Summary.title',
        Component: Summary.Component
      }
    ]
  }
};
