import * as React from 'react';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import invoke from 'lodash/fp/invoke';

import { GlContexts } from '@kwara/models/src';
import { TransactionChannels } from '@kwara/models/src/models/Transactions';

import { Text } from '@kwara/components/src/Intl';
import { Statistic } from '@kwara/components/src/Statistic';
import { SubscribedCheckbox } from '@kwara/components/src/Form';
import { Currency, Date, Time } from '@kwara/components/src/Intl';
import StatusTag from '@kwara/components/src/StatusTag';
import { Bank as BankName, BankBranch as BankBranchName } from '@kwara/components/src/Bank';

import Table, { Heading, Row, Cell, Collapsible, Footer } from '../../../../components/Table';
import { useSaccoProfileContext } from '../../../../models/Profile/ProfileProvider';

function PaymentMethod({ transaction }) {
  const store = useSaccoProfileContext();
  const transactionChannels = store.transactionChannels;

  if (!transaction.paymentMethod) {
    return null;
  }

  const transactionChannel = find(tc => tc.id === transaction.paymentMethod, transactionChannels);

  if (!transactionChannel || transactionChannel.isBaseChannel) {
    return (
      <span>
        {' '}
        - <Text id={`TransactionMethod.${transaction.paymentMethod}`} />
      </span>
    );
  }

  return <span> - {transactionChannel.name}</span>;
}

const getCreatedBy = transaction => {
  if (transaction.type === GlContexts.WITHDRAWAL && transaction.paymentMethod === TransactionChannels.mpesa) {
    return get('memberName', transaction);
  }

  const userName = invoke('user.fullName', transaction);
  const userId = get('user.id', transaction);
  if (!userName && !userId) {
    //return null if both of them are undefined
    return null;
  }
  //show userName or usedId even when one of them is missing.
  return `${userName ?? ''} (${userId ?? '-'})`;
};

export const TransactionsTable = ({ transactions, onNext, hasMore, isLoading, canReview }) => {
  return (
    <Table
      footer={
        <Footer
          colSpan={9}
          hasMore={hasMore}
          isLoading={isLoading}
          onNext={onNext}
          //errors={get('error.messages', r)}
          items={transactions}
        />
      }
      heading={
        <Row>
          <Heading width="50px" />
          <Heading width="75px" translationId="Finance.TransactionsTable.header.id" />
          <Heading translationId="Finance.TransactionsTable.header.type" />
          <Heading width="200px" translationId="Finance.TransactionsTable.header.account" />
          <Heading translationId="Finance.TransactionsTable.header.amount" />
          <Heading translationId="Finance.TransactionsTable.header.branch" />
          <Heading translationId="Finance.TransactionsTable.header.valueDate" />
          <Heading translationId="Finance.TransactionsTable.header.status" />
          <Heading iconSpacer />
        </Row>
      }
    >
      {map(t => {
        const approver = get('loanTransactionReviewSet.user', t) || get('savingsTransactionReviewSet.user', t);

        return (
          <Collapsible key={t.id}>
            <Row className="kw-text-small" key={t.id}>
              <Cell>
                <SubscribedCheckbox
                  hidden={!canReview || (t.state !== 'pending_approval' && t.state !== 'pending')}
                  name="transactions"
                  value={t.id}
                  showInfo={false}
                />
              </Cell>
              <Cell>{t.id}</Cell>
              <Cell>
                <div>
                  <Text id={`TransactionType.${t.type}`} />
                  <PaymentMethod transaction={t} />
                </div>
              </Cell>
              <Cell>
                {t.savingsId || t.loanId}
                {t.linkedAccountId ? ` → ${t.linkedAccountId}` : null}
              </Cell>
              <Cell>
                <Currency value={t.amount} />
              </Cell>
              <Cell>{get('user.branch.name', t)}</Cell>
              <Cell>
                <div>
                  <Date value={t.valueDate || t.createdAt} />
                </div>
                <div>
                  <Time value={t.valueDate || t.createdAt} />
                </div>
              </Cell>
              <Cell>
                <StatusTag state={t.status()} />
              </Cell>
            </Row>
            <Row className="bg-light-grey-300">
              <Cell />
              <Cell colSpan={2}>
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.createdBy" />}
                  size="tiny"
                  value={getCreatedBy(t)}
                />
                <Statistic
                  compact
                  size="tiny"
                  title={<Text id="Finance.TransactionsTable.approvedBy" />}
                  value={approver ? `${invoke('fullName', approver)} (${get('id', approver)})` : null}
                />
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.reference" />}
                  size="tiny"
                  value={t.reference}
                />
              </Cell>
              <Cell colSpan={1}>
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.memberId" />}
                  size="tiny"
                  value={get('memberId', t)}
                />
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.memberName" />}
                  size="tiny"
                  value={get('memberName', t)}
                />
              </Cell>
              <Cell colSpan={2}>
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.bankName" />}
                  size="tiny"
                  value={t.bankName ? <BankName bankCode={t.bankName} /> : null}
                />
                <Statistic
                  compact
                  size="tiny"
                  title={<Text id="Finance.TransactionsTable.bankBranch" />}
                  value={t.bankBranch ? <BankBranchName bankCode={t.bankName} branchCode={t.bankBranch} /> : null}
                />
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.accountNumber" />}
                  size="tiny"
                  value={t.accountNumber}
                />
              </Cell>
              <Cell colSpan={2}>
                <Statistic
                  compact
                  size="tiny"
                  title={<Text id="Finance.TransactionsTable.chequeNumber" />}
                  value={t.chequeNumber}
                />
                <Statistic
                  compact
                  title={<Text id="Finance.TransactionsTable.chequeDrawer" />}
                  size="tiny"
                  value={t.drawer}
                />
              </Cell>
            </Row>
          </Collapsible>
        );
      }, transactions)}
    </Table>
  );
};
