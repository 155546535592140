import React from 'react';
import { useIntl } from 'react-intl';

import { LoanSubstepProps } from 'GlobalTypes';

import { LoanTopupTemplate } from '@kwara/models/src/models/v1/Templates/LoanTopupTemplate';
import { min, formatIsoDate, getCurrentDate } from '@kwara/lib/src/dates';

const sharedProps = { showInfo: false, required: true };

function DisbursementAndRepayment({
  DatePicker,
  formProps: {
    values: {
      anticipatedDisbursementDate,
      submittedAt,
      loan: { firstRepaymentDate }
    }
  }
}: LoanSubstepProps) {
  const intl = useIntl();

  const inputPlaceholder = intl.formatMessage({ id: 'LoanTopupV1.DisbursementAndRepayment.datePlaceholder' });

  return (
    <>
      <DatePicker
        {...sharedProps}
        labelId="LoanTopupV1.DisbursementAndRepayment.submittedAtDate"
        name="submittedAt"
        inputPlaceholder={inputPlaceholder}
        value={submittedAt ? formatIsoDate(new Date(submittedAt)) : null}
        disabledDays={
          anticipatedDisbursementDate
            ? { after: min(getCurrentDate(), anticipatedDisbursementDate) }
            : { after: getCurrentDate() }
        }
      />

      <DatePicker
        {...sharedProps}
        labelId="LoanTopupV1.DisbursementAndRepayment.anticipatedDisbursementDate"
        name="anticipatedDisbursementDate"
        inputPlaceholder={inputPlaceholder}
        value={anticipatedDisbursementDate ? formatIsoDate(new Date(anticipatedDisbursementDate)) : null}
        disabledDays={{ before: submittedAt }}
      />

      <DatePicker
        {...sharedProps}
        labelId="LoanTopupV1.DisbursementAndRepayment.anticipatedFirstRepaymentDate"
        name="loan.firstRepaymentDate"
        inputPlaceholder={inputPlaceholder}
        value={firstRepaymentDate ? formatIsoDate(new Date(firstRepaymentDate)) : null}
        disabledDays={{ before: anticipatedDisbursementDate }}
      />
    </>
  );
}

export default {
  Component: DisbursementAndRepayment,
  validate: {
    ['submittedAt']: {
      isRequired: () => true,
      isNotFuture: true
    },
    ['anticipatedDisbursementDate']: {
      isRequired: () => true,
      custom(value: string, allData: { submittedAt: string }) {
        if (value && allData.submittedAt && value < allData.submittedAt) {
          return 'PROBLEM';
        }
      }
    },
    ['loan.firstRepaymentDate']: {
      isRequired: () => true,
      custom(value: string, allData: { anticipatedDisbursementDate: string }) {
        if (value && allData.anticipatedDisbursementDate && value < allData.anticipatedDisbursementDate) {
          return 'PROBLEM';
        }
      }
    }
  },
  fetchBalances: async (data, onChange) => {
    const template = await LoanTopupTemplate.extraParams({
      value_date: formatIsoDate(data.anticipatedDisbursementDate)
    })
      .find(data.loan.id)
      .then(r => r.data);

    onChange({
      desiredPrincipalBalance: template.principalBalance,
      desiredInterestBalance: template.interestBalance,
      desiredFeesBalance: template.feesBalance,
      desiredPenaltyBalance: template.penaltiesBalance,
      totalRolloverBalance: template.payoffAmount,
      annualInterestRate: template.annualInterestRate,
      charges: template.charges || [],
      chargeOptions: template.chargeOptions
    });
  }
};
