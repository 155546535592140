/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';

import { CHARGE_TIMES, LoanProductChargeT } from '@kwara/models/src/models/v1/LoanProducts';

import { LoanTopupFormTypes } from '../..';

export type ArgType = {
  chargeOptions: LoanProductChargeT[];
  charges: LoanProductChargeT[];
  anticipatedDisbursementDate: string;
  onChange(data: Partial<LoanTopupFormTypes>): Promise<LoanTopupFormTypes>;
};

export function useInitializeValues({ chargeOptions, charges, anticipatedDisbursementDate, onChange }: ArgType) {
  const initializeDueDates = useCallback(
    (loanProductCharges: LoanProductChargeT[], anticipatedDisbursementDate: string) => {
      return loanProductCharges.map(charge => {
        const isSpecifiedDueDate = charge.chargeTime === CHARGE_TIMES.specifiedDueDate;
        const date = charge.dueDate || anticipatedDisbursementDate;
        const dueDate = isSpecifiedDueDate ? date : null;
        return { ...charge, dueDate };
      });
    },
    []
  );

  const initializeChargesDueDate = useCallback(() => {
    const newCharges = initializeDueDates(charges, anticipatedDisbursementDate);
    onChange({ charges: newCharges });
  }, [anticipatedDisbursementDate]);

  const initializeChargeOptionsDueDate = useCallback(() => {
    const newChargeOptions = initializeDueDates(chargeOptions, anticipatedDisbursementDate);
    onChange({ chargeOptions: newChargeOptions });
  }, [anticipatedDisbursementDate]);

  useEffect(() => {
    initializeChargesDueDate();
    initializeChargeOptionsDueDate();
  }, [initializeChargesDueDate, initializeChargeOptionsDueDate]);
}
