import React from 'react';
import pick from 'lodash/fp/pick';

import { LoanSubstepProps } from 'LoanSharedTypes';

import { LoanAddData } from '../..';
import { useGetLoanSchedulePreview } from './useGetLoanSchedulePreview';
import { Schedule } from '../../../../LoanSchedulePreview/components/Schedule';

export type LoanSchedulePreviewProps = LoanSubstepProps<LoanAddData>;

export function LoanSchedulePreview(props: LoanSchedulePreviewProps) {
  const {
    StackChild,
    formProps: { values }
  } = props;

  const { schedule, isLoading, error } = useGetLoanSchedulePreview(
    pick(
      [
        'product',
        'member',
        'amount',
        'loanDuration',
        'firstRepaymentDate',
        'anticipatedDisbursementDate',
        'submittedAt',
        'charges',
        'repaymentPeriod',
        'repaymentPeriodUnit'
      ],
      values
    )
  );

  return (
    <StackChild size="wide">
      <Schedule isLoading={isLoading} error={error} schedule={schedule} />
    </StackChild>
  );
}
