import { addMany } from '@kwara/lib/src/currency';
import { LoanTopupFormTypes } from '../..';

type Securities = Array<{
  amount?: string | null;
  isMarkedForDestruction?: boolean;
  [key: string]: any;
}>;

/**
 * @getSecurityAmounts calculates the total amount of securities provided by
 * collaterals and guarantors, and compares it to the required threshold based
 * on the new principal balance.
 * @param {LoanTopupFormTypes} data - The `data` parameter is an object that contains
 * information about a loan application. It has the following properties:
 * @returns an object with two properties: "securitiesTotal" and
 * "requiredSecuritiesThreshold".
 */
function getSecurityAmounts(data: LoanTopupFormTypes) {
  const { collaterals, guarantors, product } = data;
  const newPrincipalBalance = addMany([data.topupAmount, data.totalRolloverBalance]);
  const securities: Securities = (collaterals as Securities).concat(guarantors);
  const securitiesTotal: number = securities.reduce((totals, security) => {
    if (!security.isMarkedForDestruction) totals += Number(security.amount || 0);
    return totals;
  }, 0);
  const minSecurity = product.security?.required_guaranties || 0;
  const minSecurityPercentage = minSecurity / 100;
  const requiredSecuritiesThreshold = minSecurityPercentage * Number(newPrincipalBalance);

  return { securitiesTotal, requiredSecuritiesThreshold };
}

/**
 * The function checks if the total amount of securities is
 * greater than or equal to the required threshold.
 * @param {LoanTopupFormTypes} data - The `data` parameter is an object
 * that contains loan form data. It likely includes information such
 * as the loan amount, interest rate, borrower details, and any
 * securities provided by the borrower.
 * @returns a boolean value.
 */
function checkIsSecuritiesValid(data: LoanTopupFormTypes) {
  const { securitiesTotal, requiredSecuritiesThreshold } = getSecurityAmounts(data);

  return securitiesTotal >= requiredSecuritiesThreshold;
}

export { getSecurityAmounts, checkIsSecuritiesValid };
