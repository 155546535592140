import { InferredModel } from 'GlobalTypes';
import { attr, hasMany } from 'spraypaint';

import Base from '../../Base';

export const LoanTopupTemplate = Base.extend({
  static: {
    jsonapiType: 'loan_topup_templates',
    endpoint: '/templates/loan_topup'
  },
  attrs: {
    memberName: attr(),
    memberId: attr(),
    productName: attr(),
    loanAccountId: attr(),
    annualInterestRate: attr(),
    principalBalance: attr(),
    feesBalance: attr(),
    interestBalance: attr(),
    penaltyBalance: attr(),
    payoffAmount: attr(),
    charges: hasMany(),
    chargeOptions: hasMany()
  }
});

export interface LoanTopupTemplateT extends Omit<InferredModel<typeof LoanTopupTemplate>, 'errors'> {
  memberName: string;
  memberId: string;
  productName: string;
  loanAccountId: string;
  annualInterestRate: string;
  principalBalance: number;
  feesBalance: number;
  interestBalance: number;
  penaltyBalance: number;
  payoffAmount: number;
}
