import React from 'react';

import { LoanSubstepProps, ApiError } from 'GlobalTypes';

import { Loadable } from '@kwara/components/src/Loadable';
import { addMany } from '@kwara/lib/src/currency';

import { Schedule as ScheduleTable } from '../../../LoanDetail/components/Schedule';
import { useGetLoanSchedulePreview } from '../../../Loan/LoanAdd/components/LoanSchedulePreview/useGetLoanSchedulePreview';

function Schedule({ formProps: { values } }: LoanSubstepProps) {
  const { isLoading, error, schedule } = useGetLoanSchedulePreview({
    product: values.product,
    amount: Number(addMany([values.totalRolloverBalance, values.topupAmount])),
    member: values?.loan?.member,
    loanDuration: values.loanDuration,
    repaymentPeriod: values?.loan?.repaymentPeriod,
    repaymentPeriodUnit: values?.loan?.repaymentPeriodUnit,
    submittedAt: values.submittedAt,
    anticipatedDisbursementDate: values.anticipatedDisbursementDate,
    firstRepaymentDate: values?.loan?.firstRepaymentDate,
    charges: values.charges
  });

  return (
    <Loadable data={schedule} error={(error as ApiError)?.errors} isLoading={isLoading}>
      {scheduleData => <ScheduleTable schedule={scheduleData} initiallyOpen groupedByYear={false} />}
    </Loadable>
  );
}

export default {
  Component: Schedule
};
