import * as React from 'react';

import * as pages from '../../../pages';
import { ROUTES } from '../../utils/routes';
import { usePermissions, useAuth } from '../../../hooks';
import { PermittedRoute } from '../PermittedRoute/PermittedRoute';

type LoanRoutesParamType = {
  loanId: string;
};

export function LoanRoutes() {
  const { AppPermissions } = usePermissions();

  const auth = useAuth();
  const isV1 = auth.isV1();

  return (
    <>
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanStatementGenerate"
        permission={AppPermissions.ViewLoan}
        path={ROUTES.loanStatementGenerate}
        render={props => (
          <pages.LoanStatementGenerate baseUrl={`/loans/${props.match.params.loanId}/statement`} {...props} />
        )}
      />
      <PermittedRoute
        exact
        name="LoanRepayment"
        permission={AppPermissions.AddRepayments}
        path={ROUTES.loanRepayment}
        render={props => (isV1 ? <pages.LoanRepaymentV1 {...props} /> : <pages.LoanPayment {...props} />)}
      />
      <PermittedRoute
        exact
        name="LoanFee"
        permission={AppPermissions.AddPenalties}
        path={ROUTES.loanFee}
        render={props => <pages.LoanFee {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanReschedule"
        permission={AppPermissions.RescheduleLoans}
        path={ROUTES.loanReschedule}
        render={props => <pages.LoanReschedule baseUrl={`/loans/${props.match.params.loanId}/reschedule`} {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanRefinance"
        permission={AppPermissions.RefinanceLoans}
        path={ROUTES.loanRefinance}
        render={props => <pages.LoanRefinance baseUrl={`/loans/${props.match.params.loanId}/refinance`} {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanWriteOff"
        permission={AppPermissions.WriteOffLoans}
        path={ROUTES.loanWriteOff}
        render={props => <pages.LoanWriteOff baseUrl={`/loans/${props.match.params.loanId}/writeoff`} {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanPayOff"
        permission={AppPermissions.PayOffLoan}
        path={ROUTES.loanPayOff}
        render={props => <pages.LoanPayOff baseUrl={`/loans/${props.match.params.loanId}/payOff`} {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanTopup"
        permission={AppPermissions.TopupLoan}
        path={ROUTES.loanTopup}
        render={props => {
          return isV1 ? <pages.LoanTopupV1 {...props} /> : <pages.LoanTopup {...props} />;
        }}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanUndoTopup"
        permission={AppPermissions.TopupLoan}
        path={ROUTES.undoTopup}
        render={props => <pages.LoanUndoTopup {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanTopupDisbursal"
        permission={AppPermissions.DisburseLoans}
        path={ROUTES.loanTopupDisbursal}
        render={props => (
          <pages.LoanTopupDisbursal baseUrl={`/loans/${props.match.params.loanId}/topupDisbursal`} {...props} />
        )}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanDisbursal"
        path={ROUTES.loanDisbursal}
        permission={AppPermissions.DisburseLoans}
        render={props => <pages.LoanDisbursal baseUrl={`/loans/${props.match.params.loanId}/disbursal`} {...props} />}
      />
      <PermittedRoute<LoanRoutesParamType>
        exact
        name="LoanAppraisal"
        path={ROUTES.loanAppraisal}
        permissionSome={AppPermissions.AppraiseLoans}
        component={pages.LoanAppraisal}
      />
      <PermittedRoute
        permission={AppPermissions.ViewLoan}
        name="LoanDetail"
        path="/loans/:loanId"
        render={props => <pages.LoanRouteDecider {...props} />}
      />
    </>
  );
}
