import React from 'react';

import { Field, Select } from '@kwara/components/src/Form';

import { Table } from './Table';
import { LoanTopupFormTypes } from '../..';
import { useChargesTable } from './useChargesTable';

export function Charges({ data, formProps, onChange }: LoanTopupFormTypes) {
  const chargeOptions = data.chargeOptions;
  const charges = formProps.values.charges || [];
  const anticipatedDisbursementDate = formProps.values.anticipatedDisbursementDate;

  const { selectableChargeOptions, selectedCharges, onRemove, onRestore, onAdd, onChangeCharge } = useChargesTable({
    chargeOptions,
    charges,
    anticipatedDisbursementDate,
    onChange
  });

  return (
    <>
      <Field>
        <Select
          size="regular"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value) onAdd(e.target.value);
          }}
        >
          <Select.Option value="" translationId="LoanAdd.Charges.Add.options.default" />
          {selectableChargeOptions.map(option => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Field>
      <Table
        anticipatedDisbursementDate={anticipatedDisbursementDate}
        charges={selectedCharges}
        onRemove={onRemove}
        onRestore={onRestore}
        onChangeCharge={onChangeCharge}
      />
    </>
  );
}

Charges.validate = {};
