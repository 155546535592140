import React from 'react';

import { LoanSubstepProps } from 'GlobalTypes';

import { Text } from '@kwara/components/src/Intl';

const getTextFieldProps = () => ({
  required: true,
  isCurrency: true,
  type: 'number',
  leftGlyph: 'Currency.orgCurrency'
});

function Balances({ data, TextField }: LoanSubstepProps) {
  return (
    <>
      <TextField
        {...getTextFieldProps()}
        name="desiredPrincipalBalance"
        labelId="LoanTopupV1.Balances.principal"
        readOnly
      />
      <TextField
        {...getTextFieldProps()}
        name="desiredInterestBalance"
        labelId="LoanTopupV1.Balances.interest"
        readOnly
      />
      <TextField {...getTextFieldProps()} name="desiredFeesBalance" labelId="LoanTopupV1.Balances.fee" readOnly />
      <TextField
        {...getTextFieldProps()}
        name="desiredPenaltiesBalance"
        labelId="LoanTopupV1.Balances.penalty"
        readOnly
      />
      <TextField
        {...getTextFieldProps()}
        name="totalRolloverBalance"
        titleId="LoanTopupV1.Balances.totalRollover"
        readOnly
      />
      <div className="mt2">
        <Text
          values={{
            valueDate: data.anticipatedDisbursementDate.toLocaleDateString('en-KE', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
          }}
          id="LoanTopupV1.Balances.explanatoryText"
        />
      </div>
    </>
  );
}

const sharedValidation = {
  isRequired: () => true,
  currency: true
  // custom: validateLoanAmount
};

export default {
  Component: Balances,
  validate: () => ({
    desiredInterestBalance: { ...sharedValidation },
    desiredFeesBalance: { ...sharedValidation },
    desiredPenaltiesBalance: { ...sharedValidation }
  })
};
